import { SocialLoginModule } from '@abacritt/angularx-social-login';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Overlay } from '@angular/cdk/overlay';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {
    APP_INITIALIZER,
    ApplicationConfig,
    ErrorHandler,
    importProvidersFrom,
    Injector,
    LOCALE_ID,
    provideZoneChangeDetection,
    Sanitizer,
} from '@angular/core';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY } from '@angular/material/autocomplete';
import { MAT_CHIPS_DEFAULT_OPTIONS } from '@angular/material/chips';
import { ErrorStateMatcher } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MAT_SELECT_CONFIG, MAT_SELECT_SCROLL_STRATEGY } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DomSanitizer, REMOVE_STYLES_ON_COMPONENT_DESTROY, ɵDomSanitizerImpl } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter, withDebugTracing, withInMemoryScrolling } from '@angular/router';
import { FuseModule } from '@fuse/fuse.module';
import { CustomTranslateLoader, getSelectedLanguage, Translations } from '@fuse/utils/translate';
import {
    CustomMissingTranslationHandler,
    DialogService,
    GatewayService,
    GlobalErrorHandler,
    GlobalHttpInterceptor,
    scrollFactory,
} from '@ms/core';
import { CustomErrorStateMatcher } from '@ms/core/util/validators';
import { DYNAMIC_MATCHER_PROVIDERS } from '@ng-dynamic-forms/core';
import {
    MissingTranslationHandler,
    provideTranslateService,
    TranslateLoader,
    TranslateService,
} from '@ngx-translate/core';
import { provideHotToastConfig } from '@ngxpert/public-api';
import { environment } from 'environments/environment';
import { firstValueFrom } from 'rxjs';
import { routes } from './app-routing';
import { fuseConfig } from './fuse-config';
import { MustHaveDefaultSchoolGuard } from './main/school-class/school-class-route.guard';
import { PaginatorI18n } from './paginator-i18n';
export const BROWSER_SANITIZATION_PROVIDERS: Array<any> = [
    { provide: Sanitizer, useExisting: DomSanitizer },
    { provide: DomSanitizer, useClass: ɵDomSanitizerImpl },
];

export const provideConfig = (gatewayService: GatewayService) => {
    return firstValueFrom(gatewayService.socialLoginConfig);
};

export const initApp = (gatewayService: GatewayService) => {
    return async () => {
        return await firstValueFrom(gatewayService.serverInfo()).then(
            result => {
                if (result) {
                    environment.serverInfo = result;
                    environment.serverInfo.userTimeZone = new Date().getTimezoneOffset();
                    console.log('SERVER-CONFIG', result);
                    console.log('ENVIRONMENT', environment);
                    gatewayService.socialLoginConfigInit();
                }
            },
            error => {
                environment.serverInfo = undefined;
                console.log('ERROR:', error);
            }
        );
    };
};

export const appConfig: ApplicationConfig = {
    providers: [
        provideAnimationsAsync(),
        { provide: LOCALE_ID, useFactory: getSelectedLanguage, deps: [TranslateService] },
        provideZoneChangeDetection({ eventCoalescing: true, runCoalescing: true }),
        provideAnimationsAsync(),
        importProvidersFrom(FuseModule.forRoot(fuseConfig), SocialLoginModule, MatSnackBarModule),
        provideRouter(routes, withInMemoryScrolling({ scrollPositionRestoration: 'top' }), withDebugTracing()),
        {
            provide: APP_INITIALIZER,
            useFactory: initApp,
            deps: [GatewayService, DialogService],
            multi: true,
        },
        Translations,
        {
            provide: MatPaginatorIntl,
            deps: [TranslateService],
            useFactory: (translateService: TranslateService) => new PaginatorI18n(translateService),
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: GlobalHttpInterceptor,
            multi: true,
            deps: [Injector],
        },
        MustHaveDefaultSchoolGuard,
        BROWSER_SANITIZATION_PROVIDERS,
        { provide: ErrorStateMatcher, useClass: CustomErrorStateMatcher },
        { provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY, useFactory: scrollFactory, deps: [Overlay] },
        { provide: MAT_CHIPS_DEFAULT_OPTIONS, useValue: { separatorKeyCodes: [ENTER, COMMA] } },
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        //TODO: Datepicker move
        { provide: REMOVE_STYLES_ON_COMPONENT_DESTROY, useValue: true },
        provideHotToastConfig(),
        {
            provide: 'SocialAuthServiceConfig',
            useFactory: provideConfig,
            deps: [GatewayService],
        },
        ...DYNAMIC_MATCHER_PROVIDERS,
        { provide: MAT_SELECT_SCROLL_STRATEGY, useFactory: scrollFactory, deps: [Overlay] },
        {
            provide: MAT_SELECT_CONFIG,
            useValue: { disableOptionCentering: true },
        },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
        provideHttpClient(withInterceptorsFromDi()),
        provideTranslateService({
            loader: {
                provide: TranslateLoader,
                useClass: CustomTranslateLoader,
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: CustomMissingTranslationHandler,
            },
        }),
    ],
};
