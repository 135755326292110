import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route } from '@angular/router';
import { DialogService } from '@ms/core/components/dialog/service/dialog.service';
import { Observable } from 'rxjs';
import { SchoolService } from '../service';

@Injectable()
export class MustHaveDefaultSchoolGuard implements CanActivate, CanLoad {
    constructor(
        private schoolService: SchoolService,
        private dialogService: DialogService
    ) {}

    canActivate() {
        console.log('MustHAveDefaultSchoolGuard');
        if (this.schoolService.getActiveSchool()) {
            return true;
        } else {
            this.dialogService.error('You must have default school to view this page');
            return false;
        }
    }

    canLoad(_route: Route): Observable<boolean> | Promise<boolean> | boolean {
        console.log('MustHAveDefaultSchoolGuard');
        if (this.schoolService.getActiveSchool()) {
            return true;
        } else {
            this.dialogService.error('You must have default school to view this page');
            return false;
        }
    }
}
